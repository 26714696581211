import { Context } from '@nuxt/types'

export default function (context: Context) {
  const path = context.route.path
  const hash = context.route.hash
  const type = context.route.query.type

  // URLの末尾にスラッシュがなければスラッシュ付けて301リダイレクト
  if (path.slice(-1) !== '/' && path !== '/404') {
    if (hash) {
      context.redirect(301, path + '/#' + hash)
    } else if (type) {
      context.redirect(301, path + '/?type=' + type)
    } else {
      context.redirect(301, path + '/')
    }
  }
}
