













































import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      locale: 'ja',
    }
  },
  created() {
    this.$nuxt.$on('updateLocale', this.setLocale)
  },
  methods: {
    setLocale(locale: string): void {
      this.locale = locale || 'ja'
    },
  },
})
