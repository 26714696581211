import { render, staticRenderFns } from "./default.vue?vue&type=template&id=54c52ef2&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/ec2-user/environment/kensakukun-app/src/components/Header.vue').default,Menu: require('/home/ec2-user/environment/kensakukun-app/src/components/Menu.vue').default,FooterNote: require('/home/ec2-user/environment/kensakukun-app/src/components/FooterNote.vue').default,Footer: require('/home/ec2-user/environment/kensakukun-app/src/components/Footer.vue').default,MyFolderModal: require('/home/ec2-user/environment/kensakukun-app/src/components/MyFolderModal.vue').default,MyFolderMsgModal: require('/home/ec2-user/environment/kensakukun-app/src/components/MyFolderMsgModal.vue').default})
