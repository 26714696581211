import ua from '~/assets/config/user_agent.json'

/**
 * スマホデバイスかどうか判定する
 * @return {boolean}
 */
export const isSpDevice = (): boolean => {
  const userAgent: string = navigator.userAgent.toLowerCase() as string

  // スマホデバイスか判定
  if ('ontouchend' in document) {
    for (const str of ua.sp) {
      const regexp: RegExp = new RegExp(str.toLowerCase(), 'g')
      if (userAgent.match(regexp) !== null) {
        return true
      }
    }
  }
  return false
}
