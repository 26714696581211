












































import Vue from 'vue'

export default Vue.extend({
  props: {
    detailCount: {
      type: Number,
      default: () => 0,
    },
    page: {
      type: Number,
      default: () => 1,
    },
    path: {
      type: String,
      default: () => '',
    },
  },
})
