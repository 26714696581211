





































































import Vue from 'vue'

export default Vue.extend({
  props: {
    parentHznHolder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hznHolder: this.$$getLoginInfo('S_HZNFOLDER'),
    }
  },
  watch: {
    // 親コンポーネントの値を監視する
    parentHznHolder(newHznHolder) {
      console.log('newHznHolder', newHznHolder)
      this.hznHolder = newHznHolder
    },
  },
  mounted() {
    // エラーメッセージは日本語
    this.$$setValidationLocale('ja')
  },
  methods: {
    /**
     * リスト検索する
     * @return {Promise<void>}
     */
    async search(): Promise<void> {
      // バリデーション
      const errorMessage = await this.$$validate()
      if (errorMessage !== '') {
        alert(errorMessage)
      }
    },

    /**
     * ログアウトする
     * @return {Promise<void>}
     */
    async logout(): Promise<void> {
      // ローディング画面を表示する
      this.$$showLoading()

      // ログアウトする
      await this.$$logout()

      // TOPへリダイレクト
      if (
        (this.$$getLoginInfo('L_LOGIN_CNO') === '6' ||
          this.$$getLoginInfo('L_LOGIN_CNO') === '7') &&
        this.$$getLoginInfo('RL_LOGIN_CNO') === '1'
      ) {
        location.href = '/devicelogout/'
      } else {
        location.href = '/logout/'
      }
    },

    /**
     * 表示するページを変更する
     * @params {string} path
     * @params {string} target
     * @return {void}
     */
    changePage(path: string, target: string = ''): void {
      // メンテナンス中か確認する
      if (this.$$checkMaintenance(path)) {
        alert(this.$t('message.maintenance'))
        return
      }

      // ページのアクセス権を確認する
      if (!this.$$checkAccessRights(path)) {
        alert(this.$t('message.noAccessRights'))
        return
      }

      // ページ遷移
      if (target !== '') {
        window.open(path, target)
      } else if (this.$route.path !== path) {
        this.$router.push(path)
      } else {
        this.$router.go(0)
      }
    },
  },
})
