//
//
//
//

export default {
  layout: 'blank',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    // bodyにsimpleクラスを追加
    document.body.classList.add('simple')

    // ローディングを非表示にする
    this.$$hideLoading()
  },
}
