import { DangoRequest, DangoResponse } from '@/types/dango'
import { Context } from '@nuxt/types'
import { $$const } from '@/plugins/const'
import {
  storeLoginInfo,
  getLoginInfo,
  checkMaintenance,
  checkAccessRights,
  checkVersion,
} from '@/plugins/user'
import { auth } from '@/plugins/dangoapi'
import {
  StopUidError,
  StopSvsError,
  MaintenanceError,
  AuthError,
  RightsError,
  SessionTimeoutError,
  LoginOverError,
  SessionOverError,
  AccountLockError,
} from '@/plugins/exception'

export default async function (context: Context): Promise<void> {
  // クエリを初期化
  const query: DangoRequest.authIF = {
    domainNm: document.domain,
  }

  // 大量アクセス検知対象ページであればフラグをたてる
  if (
    context.route.path === '/kiji/image/' ||
    context.route.path === '/kiji/memo/'
  ) {
    query.accessChkFlg = $$const.API.accessChkFlg.TRUE
  }

  // APIをコールする
  const res: DangoResponse.authIF = await auth(query)

  if (!res || res.status !== true) {
    // セッションタイムアウトであればエラー
    if (res.errCode === 'MSTEC030006') {
      throw new SessionTimeoutError()
    }
    // システム or サービスがメンテナンス中であればエラー
    if (res.errCode === 'MSTEC030007' || res.errCode === 'MSTEC030011') {
      throw new MaintenanceError()
    }
    // 契約 or 検索サービス停止中であればエラー
    if (res.errCode === 'MSTEC030009' || res.errCode === 'MSTEC030010') {
      throw new StopSvsError()
    }
    // 最大ログイン数オーバーであればエラー
    if (res.errCode === 'MSTEC030013') {
      throw new LoginOverError()
    }
    // セッションオーバーであればエラー
    if (res.errCode === 'MSTEC030014' || res.errCode === 'MSTEC030041') {
      throw new SessionOverError()
    }
    // アカウントロックであればエラー
    if (res.errCode === 'MSTEC030046') {
      throw new AccountLockError()
    }

    // 認証エラー
    throw new AuthError()
  } else {
    if (res.loginInfo !== undefined) {
      // ユーザー情報を保存する
      storeLoginInfo(res.loginInfo as DangoResponse.loginInfo)
    } else {
      throw new AuthError()
    }

    // サービス停止中であればエラー
    if (getLoginInfo('SV_STATE') === $$const.API_RESPONSE.SV_STATE.STOP) {
      throw new StopSvsError()
    }

    // サービスがメンテナンス中であればエラー
    if (
      getLoginInfo('SV_STATE') === $$const.API_RESPONSE.SV_STATE.MAINTENANCE ||
      checkMaintenance(context.route.path)
    ) {
      throw new MaintenanceError()
    }

    // アカウントが停止中であればエラー
    if (getLoginInfo('L_SVSTATUS') === $$const.API_RESPONSE.L_SVSTATUS.STOP) {
      throw new StopUidError()
    }

    // ページのアクセス権がなければエラー
    if (!checkAccessRights(context.route.path)) {
      throw new RightsError()
    }
  }

  // 大量アクセス検知の警告メッセージがあれば表示する
  if (res.warnCode === 'MSTWC030036') {
    alert(res.warnMsg)
  }

  // バージョンをチェックする
  if (!checkVersion(context.$config.VERSION)) {
    alert($$const.API_ERROR_MSG.VERSION)
  }
}
