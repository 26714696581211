import { DangoResponse } from '@/types/dango'

/**
 * @type {DangoResponse.loginInfo} ユーザー情報
 */
let loginInfo: DangoResponse.loginInfo | undefined

/**
 * ユーザー情報を保存する
 * @params {DangoResponse.loginInfo} loginInfo
 * @return {void}
 */
export const storeLoginInfo = (info: DangoResponse.loginInfo): void => {
  loginInfo = info
}

/**
 * ユーザー情報を取得する
 * @params {keyof DangoResponse.loginInfo} key
 * @return {number|string|boolean}
 */
export const getLoginInfo = (
  key: keyof DangoResponse.loginInfo
): number | string | boolean => {
  if (loginInfo === undefined) {
    return false
  }

  const info = loginInfo[key]
  if (typeof info === 'number' || typeof info === 'string') {
    return info
  } else {
    return false
  }
}

/**
 * メンテナンス中か確認する
 * @params {string} path
 * @return {boolean}
 */
export const checkMaintenance = (path: string): boolean => {
  console.log('func checkMaintenance:' + path)
  // 記事検索
  if (path.match(/^\/kiji\//)) {
    if (getLoginInfo('MAINTE_ASAHI') === 'Y') {
      return true
    }
  }

  // 英文ニュース
  if (path.match(/^\/english\//)) {
    if (getLoginInfo('MAINTE_EIBUN') === 'Y') {
      return true
    }
  }

  // 知恵蔵
  if (path.match(/^\/chiezo\//)) {
    if (getLoginInfo('MAINTE_CHIEZO') === 'Y') {
      return true
    }
  }

  return false
}

/**
 * ページのアクセス権を確認する
 * @params {string} path
 * @return {boolean}
 */
export const checkAccessRights = (path: string): boolean => {
  console.log('func checkAccessRights:' + path)
  // 記事検索
  if (path.match(/^\/kiji\//)) {
    if (
      (getLoginInfo('SV_DATA_ASAHI') !== 'Y' &&
        getLoginInfo('SV_DATA_AERA') !== 'Y' &&
        getLoginInfo('SV_DATA_WEEKLY') !== 'Y') ||
      getLoginInfo('L_OPT_ASAHI') !== 'Y'
    ) {
      return false
    }
    if (path === '/kiji/image/' && getLoginInfo('L_OPT_KIRI') !== 'Y') {
      return false
    }
  }

  // 知恵蔵
  if (path.match(/^\/chiezo\//)) {
    if (
      getLoginInfo('SV_DATA_CHIEZO') !== 'Y' ||
      getLoginInfo('L_OPT_CHIEZO') !== 'Y'
    ) {
      return false
    }
  }

  // 英文ニュース
  if (path.match(/^\/english\//)) {
    if (
      getLoginInfo('SV_DATA_CON3') !== 'Y' ||
      getLoginInfo('L_OPT_CON3') !== 'Y'
    ) {
      return false
    }
  }

  // 大学入学共通テスト
  if (path.match(/^\/dkt\//)) {
    if (getLoginInfo('L_OPT_MORNING') !== 'Y') {
      return false
    }
  }

  return true
}

/**
 * サービスの権限を確認する
 * @params {string} path
 * @return {boolean}
 */
export const checkSvData = (key: string): boolean => {
  if (key === 'ASAHI_SHIMBUN' || key === 'ASAHI_SHIMBUN_DIGITAL') {
    if (getLoginInfo('SV_DATA_ASAHI') === 'Y') {
      return true
    } else {
      return false
    }
  }
  if (key === 'AERA') {
    if (getLoginInfo('SV_DATA_AERA') === 'Y') {
      return true
    } else {
      return false
    }
  }
  if (key === 'SHUKAN_ASAHI') {
    if (getLoginInfo('SV_DATA_WEEKLY') === 'Y') {
      return true
    } else {
      return false
    }
  }

  return false
}

/**
 * バージョンをチェックする
 * @params {string} version
 * @return {boolean}
 */
export const checkVersion = (version: string): boolean => {
  let svVersion = getLoginInfo('SV_VERSION')
  if (typeof svVersion !== 'string') {
    svVersion = ''
  }
  const a = svVersion.split('.')
  const b = version.split('.')
  let ia = 0
  let ib = 0
  const limit = Math.max(a.length, b.length)
  for (let i = 0; i < limit; i++) {
    ia = parseInt(a[i]) || 0
    ib = parseInt(b[i]) || 0
    if (ia !== ib) {
      break
    }
  }
  if (ia <= ib) {
    return true
  } else {
    return false
  }
}
