






































































































































































































































































































































































































import Vue from 'vue'
import MicroModal from 'micromodal'

export default Vue.extend({
  props: {
    alertId: {
      type: String,
      default: () => '',
    },
    quote: {
      type: String,
      default: () => '',
    },
    srchCondition: {
      type: Object,
      default: () => {},
    },
    favoriteKijiInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      memo: {} as { [uuid: string]: string },
      memoFlg: 0,
      closeFlg: 0,
      detailApi: this.$$getInitialValue('detailApi'),
    }
  },
  watch: {
    // 親コンポーネントの値を監視する
    alertId(newAlertId) {
      console.log(newAlertId)
      if (newAlertId !== '') {
        if (newAlertId === 'addFavoriteKiji') {
          this.memoFlg = 0
          this.closeFlg = 0
        }

        // マイフォルダのアイコンを強調する
        this.highlightFolderIcon()

        // メッセージ用のモーダルを表示する
        MicroModal.show(newAlertId)
        this.$emit('clearAlertId')
      }
    },

    // モーダルを閉じるフラグを監視する
    closeFlg() {
      MicroModal.close('addFavoriteKiji')
    },
  },
  mounted() {
    // リサイズ時にマイフォルダのアイコンを強調する
    window.addEventListener('resize', () => {
      this.highlightFolderIcon()
    })

    // モーダル
    MicroModal.init({
      disableScroll: true,
      awaitCloseAnimation: true,
      awaitOpenAnimation: true,
    })
  },
  methods: {
    /**
     * メモ欄を表示する
     * @return {void}
     */
    openMemo(): void {
      this.memoFlg = 1
    },

    /**
     * マイフォルダのアイコンを強調する
     * @return {void}
     */
    highlightFolderIcon(): void {
      const els = document.getElementsByClassName('alert__folder')
      if (!els) {
        return
      }
      const myFolderBtn = document.getElementById('myFolderBtn')
      if (!myFolderBtn) {
        return
      }
      const rect = myFolderBtn.getBoundingClientRect()
      Array.from(els).forEach((el: any) => {
        el.style.left = rect.left + 'px'
      })
    },
  },
})
