export class CustomError extends Error {
  constructor(e?: string) {
    super(e)
    this.name = new.target.name

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }

    // https://future-architect.github.io/typescript-guide/exception.html
    // 下記の行はTypeScriptの出力ターゲットがES2015より古い場合(ES3, ES5)のみ必要
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class NotFoundError extends CustomError {}
export class NetworkError extends CustomError {}
export class StopUidError extends CustomError {}
export class StopSvsError extends CustomError {}
export class MaintenanceError extends CustomError {}
export class AuthError extends CustomError {}
export class RightsError extends CustomError {}
export class DeviceError extends CustomError {}
export class SessionTimeoutError extends CustomError {}
export class LoginOverError extends CustomError {}
export class SessionOverError extends CustomError {}
export class AccountLockError extends CustomError {}
export class SystemError extends CustomError {}
