import { render, staticRenderFns } from "./login.vue?vue&type=template&id=410512b8&"
import script from "./login.vue?vue&type=script&lang=ts&"
export * from "./login.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/ec2-user/environment/kensakukun-app/src/components/Header.vue').default,FooterNote: require('/home/ec2-user/environment/kensakukun-app/src/components/FooterNote.vue').default,Footer: require('/home/ec2-user/environment/kensakukun-app/src/components/Footer.vue').default})
