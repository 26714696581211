import Vue from 'vue'
import dayjs from 'dayjs'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
  setInteractionMode,
} from 'vee-validate'
import ja from '~/locales/vee-validate/ja.json'
import en from '~/locales/vee-validate/en.json'
const rules = require('vee-validate/dist/rules')

// インターフェースを追加
declare module 'vue/types/vue' {
  interface Vue {
    $$setValidationLocale(locale: string): void
  }
}

// IEバグ対策
setInteractionMode('eager')

// 全てのルールをインポートする
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

// カスタムルール

// いずれかの項目が空の場合はエラー
extend('all_empty', {
  validate(value: string, { str1, str2 }: any) {
    if (str2 !== undefined && typeof str2 === 'string') {
      return value !== '' || str1 !== '' || str2 !== ''
    } else {
      return value !== '' || str1 !== ''
    }
  },
  params: [
    { name: 'str1', isTarget: true },
    { name: 'str2', isTarget: true },
  ],
  computesRequired: true,
})

// いずれかの項目が空の場合はエラー
extend('all_empty2', {
  validate(value: string, { str1, str2, str3 }: any) {
    if (str3 !== undefined && typeof str3 === 'string') {
      return value !== '' || str1 !== '' || str2 !== '' || str3 !== ''
    } else {
      return value !== '' || str1 !== '' || str2 !== ''
    }
  },
  params: [
    { name: 'str1', isTarget: true },
    { name: 'str2', isTarget: true },
    { name: 'str3', isTarget: true },
  ],
  computesRequired: true,
})

// いずれかの項目が空の場合はエラー
extend('all_empty3', {
  validate(value: string, { str1, str2, str3, str4 }: any) {
    if (str4 !== undefined && typeof str4 === 'string') {
      return (
        value !== '' || str1 !== '' || str2 !== '' || str3 !== '' || str4 !== ''
      )
    } else {
      return value !== '' || str1 !== '' || str2 !== '' || str3 !== ''
    }
  },
  params: [
    { name: 'str1', isTarget: true },
    { name: 'str2', isTarget: true },
    { name: 'str3', isTarget: true },
    { name: 'str4', isTarget: true },
  ],
  computesRequired: true,
})

// いずれかの項目が空の場合はエラー
extend('all_empty6', {
  validate(value: string, { str1, str2, str3, str4, str5, str6 }: any) {
    return (
      value !== '' ||
      str1 !== '' ||
      str2 !== '' ||
      str3 !== '' ||
      str4 !== '' ||
      str5 !== '' ||
      str6 !== ''
    )
  },
  params: [
    { name: 'str1', isTarget: true },
    { name: 'str2', isTarget: true },
    { name: 'str3', isTarget: true },
    { name: 'str4', isTarget: true },
    { name: 'str5', isTarget: true },
    { name: 'str6', isTarget: true },
  ],
  computesRequired: true,
})

// 日付存在チェック
extend('date_exist', {
  validate(value: string) {
    return value === '' || dayjs(value, 'YYYYMMDD').format('YYYYMMDD') === value
  },
  params: [],
})

// FROM日付がTO日付よりも新しい日付かのチェック
extend('date_compare', {
  validate(value: string, { str }: any) {
    return value === '' || str === '' || value <= str
  },
  params: [{ name: 'str', isTarget: true }],
})

// &+#禁止
extend('operator', {
  validate(value: string) {
    const quoteMode = 1
    // チェック対象文字列からダブルクォーテーション内の文字列を除外する
    return (
      value.$explodeByOperator(quoteMode, 0, 1).join('').match(/[&+#]/) === null
    )
  },
  params: [],
})

// "禁止
extend('phrase', {
  validate(value: string) {
    return value.match(/"/) === null
  },
  params: [],
})

// 論理演算子チェック
extend('logical_formula', {
  validate(value: string) {
    const quoteMode = 1
    // チェック対象文字列からダブルクォーテーション内の文字列を除外する
    const v = value
      .$trim()
      .$setOperator(quoteMode)
      .$explodeByOperator(quoteMode, 0, 1)
      .join('')
    return (
      v.match(/^[&+#)]/) === null &&
      v.match(/[&+#(]$/) === null &&
      v.match(/[&+#(][&+#)]/) === null &&
      v.match(/[)][(]/) === null
    )
  },
  params: [],
})

// 丸括弧ペアチェック
extend('parentheses', {
  validate(value: string) {
    const quoteMode = 1
    let num = 0
    // チェック対象文字列からダブルクォーテーション内の文字列を除外する
    for (const v of value
      .$trim()
      .$setOperator(quoteMode)
      .$explodeByOperator(quoteMode, 0, 1)) {
      if (v === '(') {
        ++num
      } else if (v === ')') {
        if (num === 0) {
          return false
        }
        --num
      }
    }
    if (num !== 0) {
      return false
    }
    return true
  },
  params: [],
})

// 丸括弧ネストチェック
extend('parentheses_nest', {
  validate(value: string) {
    const quoteMode = 1
    let num = 0
    // チェック対象文字列からダブルクォーテーション内の文字列を除外する
    for (const v of value
      .$trim()
      .$setOperator(quoteMode)
      .$explodeByOperator(quoteMode, 0, 1)) {
      if (v === '(') {
        ++num
      } else if (v === ')') {
        --num
      }
      if (num > 2) {
        return false
      }
    }
    return true
  },
  params: [],
})

// 丸括弧ネストチェック
extend('parentheses_nest4', {
  validate(value: string) {
    const quoteMode = 1
    let num = 0
    // チェック対象文字列からダブルクォーテーション内の文字列を除外する
    for (const v of value
      .$trim()
      .$setOperator(quoteMode)
      .$explodeByOperator(quoteMode, 0, 1)) {
      if (v === '(') {
        ++num
      } else if (v === ')') {
        --num
      }
      if (num > 4) {
        return false
      }
    }
    return true
  },
  params: [],
})

// 入力禁止文字チェック
extend('single_mb_char', {
  validate(value: string) {
    const quoteMode = 1
    // チェック対象文字列から演算子を除外する
    for (const v of value
      .$trim()
      .$setOperator(quoteMode)
      .$explodeByOperator(quoteMode, 1, 0)) {
      if (v.replace(/^"(.*?)"$/, '$1').length === 1) {
        const c = v.charCodeAt(0)
        if (!(c >= 0x4e00 && c <= 0xfa2d) && c !== 0xff1d && c !== 0xff0f) {
          return false
        }
      }
    }
    return true
  },
  params: [],
})

// シングルバイト文字チェック
extend('single_char', {
  validate(value: string) {
    const quoteMode = 1
    // チェック対象文字列から演算子を除外する
    for (const v of value
      .$trim()
      .$setOperator(quoteMode)
      .$explodeByOperator(quoteMode, 1, 0)) {
      if (v.replace(/^"(.*?)"$/, '$1').length === 1) {
        return false
      }
    }
    return true
  },
  params: [],
})

// アスキー文字チェック
extend('ascii', {
  validate(value: string) {
    for (let i = 0; i < value.length; i++) {
      const c = value.charCodeAt(i)
      if ((c >= 0x00 && c <= 0x1f) || c === 0x7f || c === 0x3c || c === 0x3e) {
        return false
      }
    }
    return true
  },
  params: [],
})

// 半角英数字チェック
extend('half_alphanumeric', {
  validate(value: string) {
    const quoteMode = 1
    for (const v of value
      .$trim()
      .$setOperator(quoteMode)
      .$explodeByOperator(quoteMode, 1, 0)) {
      if (v.replace(/^"(.*?)"$/, '$1').length === 1) {
        const c = v.charCodeAt(0)
        if (c >= 0x0000 && c <= 0x007e) {
          return false
        }
      }
    }
    return true
  },
  params: [],
})

// 半角カナチェック
extend('half_kana', {
  validate(value: string) {
    for (let i = 0; i < value.length; i++) {
      const c = value.charCodeAt(i)
      if (c >= 0xff61 && c <= 0xff9f) {
        return false
      }
    }
    return true
  },
  params: [],
})

// 数値、ドットチェック
extend('num_dots_commas', {
  validate(value: string) {
    return value.match(/^[0-9.,]+?$/) !== null
  },
  params: [],
})

// パスワード文字数チェック
extend('passwd_length', {
  validate(value: string) {
    return value.length >= 10
  },
  params: [],
  computesRequired: true,
})

// パスワードルールチェック
extend('passwd_rule', {
  validate(value: string) {
    return (
      value.match(/[a-z]/) !== null &&
      value.match(/[A-Z]/) !== null &&
      value.match(/[0-9]/) !== null
    )
  },
  params: [],
})

// コンポーネントを登録
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

localize('ja', ja)

/**
 * バリデーションメッセージのロケールを取得する
 * params {string} locale
 * @return any
 */
const getValidationMessage = (locale: string): any => {
  if (locale === 'ja') {
    return ja
  } else if (locale === 'en') {
    return en
  }
}

/**
 * バリデーションメッセージのロケールをセットする
 * params {string} locale
 * @return void
 */
Vue.prototype.$$setValidationLocale = function (locale: string): void {
  localize(locale, getValidationMessage(locale))
}
