import { MyFolder } from '@/types/dango'

/**
 * マイフォルダ情報
 */
let myFolderInfo = {
  QUOTE: {
    credatetime: '',
    detailCount: 0,
    detail: [],
  } as MyFolder.quoteJsonIF,
  PRESEARCH: {
    credatetime: '',
    detailCount: 0,
    detail: [],
  } as MyFolder.presearchJsonIF,
  FAVORITE_KIJI: {
    credatetime: '',
    detailCount: 0,
    detail: [],
  } as MyFolder.favoriteKijiJsonIF,
}

/**
 * マイフォルダ情報を初期化する
 * @return {void}
 */
export const initMyFolderInfo = (): void => {
  myFolderInfo = {
    QUOTE: {
      credatetime: '',
      detailCount: 0,
      detail: [],
    } as MyFolder.quoteJsonIF,
    PRESEARCH: {
      credatetime: '',
      detailCount: 0,
      detail: [],
    } as MyFolder.presearchJsonIF,
    FAVORITE_KIJI: {
      credatetime: '',
      detailCount: 0,
      detail: [],
    } as MyFolder.favoriteKijiJsonIF,
  }
}

/**
 * マイフォルダ情報を保存する
 * @params {keyof MyFolder.detailIF} key
 * @params {MyFolder.quoteJsonIF | MyFolder.presearchJsonIF | MyFolder.favoriteKijiJsonIF} info
 * @return {void}
 */
export const storeMyFolderInfo = (
  key: keyof MyFolder.detailIF,
  info:
    | MyFolder.quoteJsonIF
    | MyFolder.presearchJsonIF
    | MyFolder.favoriteKijiJsonIF
): void => {
  if (key === 'QUOTE') {
    myFolderInfo[key] = info as MyFolder.quoteJsonIF
  } else if (key === 'PRESEARCH') {
    myFolderInfo[key] = info as MyFolder.presearchJsonIF
  } else if (key === 'FAVORITE_KIJI') {
    myFolderInfo[key] = info as MyFolder.favoriteKijiJsonIF
  }
}

/**
 * マイフォルダ情報を取得する
 * @params {keyof MyFolder.detailIF} k1
 * @params {keyof MyFolder.quoteJsonIF | keyof MyFolder.presearchJsonIF | keyof MyFolder.favoriteKijiJsonIF | ''} k2
 * @return {any}
 */
export const getMyFolderInfo = (
  k1: keyof MyFolder.detailIF,
  k2:
    | keyof MyFolder.quoteJsonIF
    | keyof MyFolder.presearchJsonIF
    | keyof MyFolder.favoriteKijiJsonIF
    | '' = ''
): any => {
  if (myFolderInfo[k1]) {
    if (k2 === '') {
      return myFolderInfo[k1]
    } else if (myFolderInfo[k1][k2]) {
      return myFolderInfo[k1][k2]
    } else if (k2 === 'credatetime') {
      return ''
    } else if (k2 === 'detailCount') {
      return 0
    } else if (k2 === 'detail') {
      return []
    } else {
      return false
    }
  } else {
    return false
  }
}
