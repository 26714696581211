import axios, { AxiosRequestConfig } from 'axios'

import { DangoRequest, DangoResponse } from '@/types/dango'
import { NetworkError, AuthError } from '@/plugins/exception'

/**
 * @type {json} リクエストヘッダー
 */
const headers = {
  Accept: 'image/jpeg, application/pdf, application/json',
}

/**
 * APIをコールする
 * @params {string} api
 * @params {T} query
 * @params {number} binaryFlg
 * @return {U|void>}
 */
const callApi = async <T, U>(
  api: string,
  query: T,
  binaryFlg: number = 0
): Promise<U | void> => {
  try {
    const url = parent.$nuxt.$config.DANGO_API + '/' + api
    const config = {} as AxiosRequestConfig
    config.headers = headers
    config.withCredentials = true
    if (binaryFlg === 1) {
      config.responseType = 'arraybuffer'
    } else {
      config.responseType = 'json'
    }
    console.log(url, query, config)
    const res = await axios.post<U>(url, query, config)
    console.log(res)
    if (!res.data) {
      try {
        throw new Error('no res.data ')
      } catch (e) {
        await parent.$nuxt.$$redirectErrorPage(e)
      }
    }
    return res.data
  } catch (e) {
    if (e.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(e.response.data)
      console.error(e.response.status) // 例：400
      console.error(e.response.statusText) // Bad Request
      console.error(e.response.headers)
    } else if (e.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(e.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error(e.message)
    }
    try {
      throw new NetworkError()
    } catch (e) {
      await parent.$nuxt.$$redirectErrorPage(e)
    }
  }
}

/**
 * ログイン
 * @params {DangoRequest.loginIF} query
 * @return {Promise<DangoResponse.loginIF>}
 */
export const login = async (
  query: DangoRequest.loginIF
): Promise<DangoResponse.loginIF> => {
  const res = (await callApi<DangoRequest.loginIF, DangoResponse.loginIF>(
    parent.$nuxt.$config.API_NAME.LOGIN,
    query
  )) as DangoResponse.loginIF
  return res
}

/**
 * 認可
 * @params {DangoRequest.authIF} query
 * @return {Promise<DangoResponse.authIF>}
 */
export const auth = async (
  query: DangoRequest.authIF
): Promise<DangoResponse.authIF> => {
  const res = (await callApi<DangoRequest.authIF, DangoResponse.authIF>(
    parent.$nuxt.$config.API_NAME.AUTH,
    query
  )) as DangoResponse.authIF
  return res
}

/**
 * ログアウト
 * @params {DangoRequest.logoutIF} query
 * @return {Promise<DangoResponse.logoutIF>}
 */
export const logout = async (
  query: DangoRequest.logoutIF
): Promise<DangoResponse.logoutIF> => {
  const res = (await callApi<DangoRequest.logoutIF, DangoResponse.logoutIF>(
    parent.$nuxt.$config.API_NAME.LOGOUT,
    query
  )) as DangoResponse.logoutIF
  return res
}

/**
 * リスト検索
 * @params {DangoRequest.searchIF} query
 * @return {Promise<DangoResponse.searchIF>}
 */
export const search = async (
  query: DangoRequest.searchIF
): Promise<DangoResponse.searchIF> => {
  const res = (await callApi<DangoRequest.searchIF, DangoResponse.searchIF>(
    parent.$nuxt.$config.API_NAME.SEARCH,
    query
  )) as DangoResponse.searchIF
  return res
}

/**
 * 関連キーワード取得
 * @params {DangoRequest.relatedKeywordsIF} query
 * @return {Promise<DangoResponse.relatedKeywordsIF>}
 */
export const getRelatedKeywords = async (
  query: DangoRequest.relatedKeywordsIF
): Promise<DangoResponse.relatedKeywordsIF> => {
  const res = (await callApi<
    DangoRequest.relatedKeywordsIF,
    DangoResponse.relatedKeywordsIF
  >(
    parent.$nuxt.$config.API_NAME.RELATED_KEYWORDS,
    query
  )) as DangoResponse.relatedKeywordsIF
  return res
}

/**
 * 詳細情報取得
 * @params {DangoRequest.detailIF} query
 * @return {Promise<DangoResponse.detailIF>}
 */
export const getDetail = async (
  query: DangoRequest.detailIF
): Promise<DangoResponse.detailIF> => {
  const res = (await callApi<DangoRequest.detailIF, DangoResponse.detailIF>(
    parent.$nuxt.$config.API_NAME.DETAIL,
    query
  )) as DangoResponse.detailIF
  return res
}

/**
 * サムネイル情報取得
 * @params {DangoRequest.thumbnailIF} query
 * @return {Promise<DangoResponse.thumbnailIF>}
 */
export const getThumbnail = async (
  query: DangoRequest.thumbnailIF
): Promise<DangoResponse.thumbnailIF> => {
  const res = (await callApi<
    DangoRequest.thumbnailIF,
    DangoResponse.thumbnailIF
  >(parent.$nuxt.$config.API_NAME.THUMNAIL, query)) as DangoResponse.thumbnailIF
  return res
}

/**
 * 実データ情報取得
 * @params {DangoRequest.realDatalIF} query
 * @return {Promise<DangoResponse.realDatalIF>}
 */
export const getRealData = async (
  query: DangoRequest.realDataIF
): Promise<DangoResponse.realDataIF> => {
  const res = (await callApi<DangoRequest.realDataIF, DangoResponse.realDataIF>(
    parent.$nuxt.$config.API_NAME.REALDATA,
    query
  )) as DangoResponse.realDataIF
  return res
}

/**
 * 実データ情報取得
 * @params {DangoRequest.realDatalIF} query
 * @return {Promise<DangoResponse.realDatalIF>}
 */
export const getRealDataBinary = async (
  query: DangoRequest.realDataIF
): Promise<ArrayBuffer> => {
  const res = (await callApi<DangoRequest.realDataIF, ArrayBuffer>(
    parent.$nuxt.$config.API_NAME.REALDATA,
    query,
    1
  )) as ArrayBuffer
  return res
}

/**
 * 認証パスワード変更
 * @params {DangoRequest.changePasswordIF} query
 * @return {Promise<DangoResponse.changePasswordIF>}
 */
export const changePassword = async (
  query: DangoRequest.changePasswordIF
): Promise<DangoResponse.changePasswordIF> => {
  const res = (await callApi<
    DangoRequest.changePasswordIF,
    DangoResponse.changePasswordIF
  >(
    parent.$nuxt.$config.API_NAME.CHANGE_PASSWORD,
    query
  )) as DangoResponse.changePasswordIF
  return res
}

/**
 * マイフォルダ保存キー発行・作成
 * @params {DangoRequest.getmyfhznkeyIF} query
 * @return {Promise<DangoResponse.getmyfhznkeyIF>}
 */
export const getmyfhznkey = async (
  query: DangoRequest.getmyfhznkeyIF
): Promise<DangoResponse.getmyfhznkeyIF> => {
  const res = (await callApi<
    DangoRequest.getmyfhznkeyIF,
    DangoResponse.getmyfhznkeyIF
  >(
    parent.$nuxt.$config.API_NAME.GET_MYFOLDER_HZNKEY,
    query
  )) as DangoResponse.getmyfhznkeyIF
  return res
}

/**
 * マイフォルダ保存キー発行・作成
 * @params {DangoRequest.updmyfInfosIF} query
 * @return {Promise<DangoResponse.updmyfInfosIF>}
 */
export const updmyfInfos = async (
  query: DangoRequest.updmyfInfosIF
): Promise<DangoResponse.updmyfInfosIF> => {
  const res = (await callApi<
    DangoRequest.updmyfInfosIF,
    DangoResponse.updmyfInfosIF
  >(
    parent.$nuxt.$config.API_NAME.UPDATE_MYFOLDER_INFO,
    query
  )) as DangoResponse.updmyfInfosIF
  return res
}

/**
 * マイフォルダ利用・編集解放
 * @params {DangoRequest.myfusereleaseIF} query
 * @return {Promise<DangoResponse.myfusereleaseIF>}
 */
export const myfuserelease = async (
  query: DangoRequest.myfusereleaseIF
): Promise<DangoResponse.myfusereleaseIF> => {
  const res = (await callApi<
    DangoRequest.myfusereleaseIF,
    DangoResponse.myfusereleaseIF
  >(
    parent.$nuxt.$config.API_NAME.RELEASE_MYFOLDER,
    query
  )) as DangoResponse.myfusereleaseIF
  return res
}

/**
 * マイフォルダデータ取得
 * @params {string} path
 * @return {Promise<any>}
 */
export const getmyfdata = async (path: string): Promise<any> => {
  try {
    const url =
      parent.$nuxt.$config.DANGO_API + path + '?' + new Date().getTime()
    const config = {} as AxiosRequestConfig
    config.headers = {}
    config.headers.Accept = 'application/json'
    config.headers.Domain = document.domain
    config.withCredentials = true
    config.responseType = 'json'
    const res = await axios.get(url, config)
    console.log(url, res)
    if (!res.data) {
      try {
        throw new Error('no res.data ')
      } catch (e) {
        await parent.$nuxt.$$redirectErrorPage(e)
      }
    }
    return res.data
  } catch (e) {
    if (e.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(e.response.data)
      console.error(e.response.status) // 例：400
      console.error(e.response.statusText) // Bad Request
      console.error(e.response.headers)
    } else if (e.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(e.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error(e.message)
    }
    try {
      if (e.response.status === 403) {
        throw new AuthError()
      } else {
        throw new NetworkError()
      }
    } catch (e) {
      await parent.$nuxt.$$redirectErrorPage(e)
    }
  }
}
