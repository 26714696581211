






















import Vue from 'vue'

export default Vue.extend({
  props: {
    locale: {
      type: String,
      default: () => '',
    },
  },
})
